<template>
  <div class="allInformation">
    <el-table
      v-loading="loading"
      :data="tableData"
      style="margin-bottom: 20px"
      @selection-change="handleSelectionChange"
      @cell-click="cellClick"
    >
      <el-table-column type="selection" :selectable='checkboxSelect' width="55" />
      <el-table-column align="center" label="标题内容" width="600">
        <template slot-scope="scope">
          <div>
            <span class="circle" v-if="scope.row.unread == 0"></span>
            {{ scope.row.msgTitle }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        v-for="item in tableCols"
        :key="item.prop"
        :formatter="item.formatter"
      ></el-table-column>
    </el-table>
    <el-button
      type="primary"
      @click="
        page = 1;
        subscription();
      "
      :loading="loading"
      :disabled="subscriptionFlag"
      >标记已读</el-button
    >
    <el-button
      type="primary"
      @click="
        page = 1;
        allRead();
      "
      :loading="loading"
      :disabled="disabled"
      >全部已读</el-button
    >
    <div class="pagerWrapper">
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/common/js/hub.js";
export default {
  data() {
    return {
      page: 1,
      pageSize: 10,
      disabled: true,
      total: 0,
      loading: false,
      sysmgMsgRecordIds: [],
      tableData: [],
      tableCols: [
        {
          prop: "sendTime",
          label: this.$t("list.Sending_time"),
          width: "",
        },
        {
          prop: "msgType",
          label: "类型",
          width: "",
          formatter: (row) => {
            let obj = {
              "0": "管理员通知",
              "3": "设备异常报警",
              "2": "监控车辆入场报警",
            };
            return obj[row.msgType];
          },
        },
      ],
    };
  },
  computed: {
    subscriptionFlag: function () {
      if (this.sysmgMsgRecordIds.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    checkboxSelect(row) {
      return row.unread == 0
    },
    allRead() {
      this.$confirm("确认要将所有消息标记已读吗?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/sysmgMsgConf/ignore", {
              data: {
                msgType: 3,
              },
            })
            .then((res) => {
              this.searchData();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleSelectionChange(val) {
      this.sysmgMsgRecordIds = [];
      val.forEach((e) => {
        this.sysmgMsgRecordIds.push(e.msgRecordId);
      });
      this.sysmgMsgRecordIds = this.sysmgMsgRecordIds.join(",");
    },
    cellClick(row, column, cell, event) {
      // 点击小铃铛重新获取数据
      Bus.$emit("getUnread");
      this.subscription(row.msgRecordId);
      this.$router.push({
        path: "/equipmentAlarmInfoDetails",
        query: { ...row },
      });
    },
    subscription(msgRecordId) {
      let sysmgMsgRecordIds = msgRecordId
        ? `${msgRecordId}`
        : this.sysmgMsgRecordIds;
      this.$axios
        .post("/acb/2.0/sysmgMsgConf/update", {
          data: {
            sysmgMsgRecordIds,
          },
        })
        .then((res) => {
          this.searchData();
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    searchData() {
      // 点击小铃铛重新获取数据
      Bus.$emit("getUnread");
      this.$axios
        .get("/acb/2.0/sysmgMsgConf/recordlist", {
          data: {
            userId: sessionStorage.userId,
            msgType: 3,
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.tableData = [];
          this.tableData = res.value.list;
          this.total = res.value.total * 1 || 0;
          if (this.tableData.length > 0 && this.tableData[0].unread === 0) {
            this.disabled = false;
          } else {
            this.disabled = true;
          }
        });
    },
  },
  mounted() {
    this.searchData();
  },
  activated() {
    this.searchData();
  },
};
</script>
<style lang="stylus" scoped>
.allInformation {
  .circle {
    display: inline-block;
    position: absolute;
    left: 40px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background: blue;
    border-radius: 50%;
  }
  >>>.el-table__row {
    cursor: pointer;
  }
  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;
    .export {
      font-size: 12px;
    }
    .iconfont {
      margin-right: 0;
    }
  }
}
</style>
